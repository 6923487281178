<template>
  <div>
    <b-overlay :show="loading">
      <TableDataWidget
        ref="refTableSaleInfo"
        :columns="cColumns"
        view-able
        :items="items"
        @clickView="row => $emit('clickView', { row, queryDataType: queryDataType })"
      >
        <template #custom_header_total>
          <span>
            ยอดขาย
            <small class="text-success">(หักส่วนต่างแล้ว)</small>
          </span>
        </template>
      </TableDataWidget>
      <!-- <pre>{{ items }}</pre> -->
    </b-overlay>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    columns: {
      type: Array,
      default: () => [],
    },
    actionType: {
      type: String,
      default: 'fetch', // fetch || navigate
    },
    queryDataType: {
      type: String,
      default: 'monthlySummaryDay', // monthlySummaryDay || monthlySummaryDayDetail || monthlySummaryDayDetailOwn || monthlySummaryDayDetailListData
    },
    date: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      items: [],
    }
  },
  computed: {
    cColumns() {
      if (this.queryDataType === 'monthlySummaryDay') {
        return [
          {
            label: 'วันที่ (วัน-เดือน-ปี)',
            field: 'day',
            type: 'date',
            dateInputFormat: 'yyyy-mm-dd',
            dateOutputFormat: 'dd-mm-yyyy',
            thClass: 'text-left',
            tdClass: 'text-left',
          },
          {
            label: 'custom_header_total',
            field: 'total',
            type: 'number',
          },
          {
            label: '',
            field: 'action',
            sortable: false,
            thClass: 'text-center',
            tdClass: 'text-center',
          },
        ]
      }

      if (this.queryDataType === 'monthlySummaryDayFree') {
        return [
          {
            label: 'วันที่ (วัน-เดือน-ปี)',
            field: 'day',
            type: 'date',
            dateInputFormat: 'yyyy-mm-dd',
            dateOutputFormat: 'dd-mm-yyyy',
            thClass: 'text-left',
            tdClass: 'text-left',
          },
          {
            label: 'ยอดดาวน์โหลด (รูป)',
            field: 'download_total',
            thClass: 'text-right',
            tdClass: 'text-right',
          },
          {
            label: '',
            field: 'action',
            sortable: false,
            thClass: 'text-center',
            tdClass: 'text-center',
          },
        ]
      }

      if (this.queryDataType === 'monthlySummaryDayDetailFree') {
        return [
          {
            label: 'หัวข้อรูปภาพ',
            field: 'topic_image_data_name',
          },
          {
            label: 'ยอดดาวน์โหลด (รูป)',
            field: 'download_total',
            thClass: 'text-right',
            tdClass: 'text-right',
          },
          {
            label: '',
            field: 'action',
            sortable: false,
            thClass: 'text-center',
            tdClass: 'text-center',
          },
        ]
      }

      if (this.queryDataType === 'overviewMonth') {
        return [
          {
            label: 'หัวข้อรูปภาพ',
            field: 'topic_image_data_name',
          },
          {
            label: 'ยอดดาวน์โหลด (รูป)',
            field: 'topic_image_data_download_total',
          },
          {
            label: 'custom_header_total',
            field: 'topic_image_data_sale_total',
            type: 'number',
          },
          {
            label: '',
            field: 'action',
            sortable: false,
            thClass: 'text-center',
            tdClass: 'text-center',
          },
        ]
      }

      return [
        {
          label: 'หัวข้อรูปภาพ',
          field: 'topic_image_data_name',
        },
        {
          label: 'ยอดดาวน์โหลด (รูป)',
          field: 'topic_list_data_download_total',
        },
        {
          label: 'custom_header_total',
          field: 'topic_image_data_sale_total',
          type: 'number',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
  },
  watch: {
    date() {
      this.getDataByQuery()
    },
  },
  created() {
    this.getDataByQuery()
  },
  methods: {
    async getDataByQuery() {
      this.loading = true
      const resp = await this.api.post('api/report/sale-info/index', {
        queryDataType: this.queryDataType,
        date: this.date,
      })
      // console.log('getDataByQuery', resp)
      if (Array.isArray(resp)) {
        this.items = [...resp]
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
